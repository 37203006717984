import React from "react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

import styles from './CustomStepper.module.scss';
import "./CustomStepper.scss";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      height: 4,
      zIndex: 1
    },
    [`&::after`]: {
        left: 0,
        position: 'absolute',
        top: '50%',
        height: '2px',
        backgroundColor: '#E8E8E8'
    }
  }));

function CustomStepper(props) {
  const { steps, current, progress } = props;

  const ProgressBar = ({ current, step, progress }) => {
    let value = 0;
    if(current+1 === step) {
      value = progress
    } else if(current >= step) {
      value = 100
    }
     
    return <BorderLinearProgress variant="determinate" value={value} className={`linearProgress`} />
  }
  
  function renderStep(step, key) {
    const { current, progress } = this;
    const done = key < current+1;

    return (
      <li className={`stepper__step`} key={key}>
        <div className={`labelContainer`}>
          <div className={`${styles.stepContainer} ${done ? styles.complitedStep : ''}`}>
            <img src={!done ? step.inactiveIcon : step.activeIcon} alt="" />
          </div>
        </div>
        {!!key && <ProgressBar current={current} step={key} progress={progress} />}
      </li>
    )
  }

  return (
    <ul className={styles.stepper}>
      {steps.map(renderStep, { current, progress })}
    </ul>
  )
}

export default CustomStepper;