import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/goal/targetZone.json";
import useJsonParse from "../../../hooks/useJsonParse";
import CheckboxCard from "../../UI/checkboxCard/CheckboxCard";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import eventData from "../../../core/static/eventData.json";

import styles from "./TargetZone.module.scss";

const TargetZone = props => {
    const { nextStep, subdomain } = props;
    const [cards, setCards] = useState();
    const [activeCards, setActiveCards] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const parsedData = useJsonParse(data);
    const eventInfo = eventData[subdomain].targetZone;

    const selectCard = card => {
        if(card.checked) {
            setActiveCards((prev => [...prev, card]))
        } else {
            const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
            setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
        }
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_target_zone_appeared');
    }, [])

    useEffect(() => {
        if(activeCards.length !== 0) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [activeCards])
    
    useEffect(() => {
        setCards(parsedData);
    }, [parsedData]);

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_target_zone_continue_tapped', {answer: activeCards.map(card => { return card.title})});
        nextStep();
    }

    return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.cardTitle}>Choose your target zone</h1>
                <span className={`${styles.marginTitle} componentSubtitle`}>
                    Our workout dance program will focus on that area
                </span>
                <div className={`${styles.cardsContainer} flex-column cardsContainer`}>
                    <div className={styles.cardsImage}>
                        <img className={styles.image} src={eventInfo.cardsImage} style={eventInfo.cardsImageStyle} alt="" />
                    </div>
                    <div className={`${styles.cardsWrapper} flex-column`}>
                        { cards?.map(card => ( <CheckboxCard key={card.id} data={card} smallCard={true} selectCard={selectCard}/> )) }
                    </div>
                    <ContinueButton nextStep={clickContinue} disableButton={disableButton}/>
                </div>
            </div>
        </>
    )
}   

export default TargetZone;