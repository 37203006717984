import { useEffect, useState } from "react";
import AboutResult from "../../components/about/aboutResult/AboutResult";
import Age from "../../components/about/age/Age";
import CurrentWeight from "../../components/about/currentWeight/CurrentWeight";
import Gender from "../../components/about/gender/Gender";
import GoalWeight from "../../components/about/goalWeight/GoalWeight";
import Height from "../../components/about/height/Height";
import CurrentBody from "../../components/goal/currentBody/CurrentBody";
import GoalResult from "../../components/goal/goalResult/GoalResult";
import MainGoal from "../../components/goal/mainGoal/MainGoal";
import Motivation from "../../components/goal/motivation/Motivation";
import TargetBody from "../../components/goal/targetBody/TargetBody";
import ActivityLevel from "../../components/lifestyle/activityLevel/ActivityLevel";
import Busy from "../../components/lifestyle/busy/Busy";
import Children from "../../components/lifestyle/children/Children";
import Diet from "../../components/lifestyle/diet/Diet";
import LifestyleResult from "../../components/lifestyle/lifestyleResult/LifestyleResult";
import DanceExperience from "../../components/plan/danceExperience/DanceExperience";
import DanceVsExercises from "../../components/plan/danceVsExercises/DanceVsExercises";
import DanceStyles from "../../components/plan/danceStyles/DanceStyles";
import FirstQuestion from "../../components/plan/firstQuestion/FirstQuestion";
import PlanResult from "../../components/plan/planResult/PlanResult";
import SecondQuestion from "../../components/plan/secondQuestion/SecondQuestion";
import Session from "../../components/plan/session/Session";
import ThirdQuestion from "../../components/plan/thirdQuestion/ThirdQuestion";
import Times from "../../components/plan/times/Times";
import YogaOrFitness from "../../components/plan/yogaOrFitness/YogaOrFitness";
import CustomStepper from "../../components/UI/customStepper/CustomStepper";
import GoalBanner from "../../components/goal/goalBanner/GoalBanner";
import TargetZone from "../../components/goal/targetZone/TargetZone";
import stepsData from "../../core/static/stepper.json";
import stepRulesData from "../../core/static/steps.json";

import styles from "./StepsComponent.module.scss";

const steps = stepsData;
const stepRules = stepRulesData;
const stepSections = stepRulesData["sections"];

const StepsComponent = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [progress, setProgress] = useState(0);
    const [stepComponent, setStepComponent] = useState();
    const [title, setTitle] = useState("");
    const subdomain = localStorage.getItem('subdomain') || "a";
    const stepsOrder = stepRules[subdomain];
    const activeStepsCount = stepsOrder[stepSections[activeStep]].length - 1

    const prevStep = () => {
        setActiveStep((prev) => prev - 1);
        setProgress(stepsOrder[stepSections[activeStep - 1]].length - 1);
    };

    const nextStep = () => {
        setProgress(0);
        setActiveStep((prev) => prev + 1);
    };

    function findStepTitle(step) {
        switch(step) {
            case 0:
                return "Goal"
            case 1:
                return "About you"
            case 2:
                return "Lifestyle"
            case 3:
                return "Your plan"
            default:
                return "Goal"
        }
    }

    useEffect(() => {
        setTitle(findStepTitle(activeStep))
    }, [activeStep])

    const handleNext = () => {
        progress !== (activeStepsCount) ? setProgress((prev) => prev + 1) : nextStep();
    };

    const handlePrev = () => {
        progress === 0 ? prevStep() : setProgress((prev) => prev - 1);
    }

    const findStep = (value) => {
        const caseValue = stepsOrder[stepSections[activeStep]][value];
        switch(activeStep) {
            case 0:
                switch(caseValue) {
                    case "MainGoal":
                        return <MainGoal nextStep={handleNext} subdomain={subdomain} />;
                    case "GoalBanner":
                        return <GoalBanner nextStep={handleNext} subdomain={subdomain} />;
                    case "Motivation":
                        return <Motivation nextStep={handleNext} subdomain={subdomain} />;
                    case "TargetZone":
                        return <TargetZone nextStep={handleNext} subdomain={subdomain} />;
                    case "CurrentBody":
                        return <CurrentBody nextStep={handleNext} subdomain={subdomain} />;
                    case "TargetBody":
                        return <TargetBody nextStep={handleNext} subdomain={subdomain} />;
                    case "GoalResult":
                        return <GoalResult nextStep={handleNext} subdomain={subdomain} />;
                    default:
                        return <MainGoal nextStep={handleNext} subdomain={subdomain} />;
                }
            case 1:
                switch(caseValue) {
                    case "Gender":
                        return <Gender nextStep={handleNext} subdomain={subdomain} />;
                    case "Age":
                        return <Age nextStep={handleNext} subdomain={subdomain} />;
                    case "Height":
                        return <Height nextStep={handleNext} subdomain={subdomain} />;
                    case "CurrentWeight":
                        return <CurrentWeight nextStep={handleNext} subdomain={subdomain} />;
                    case "GoalWeight":
                        return <GoalWeight nextStep={handleNext} subdomain={subdomain} />;
                    case "AboutResult":
                        return <AboutResult nextStep={handleNext} subdomain={subdomain} />
                    default:
                        return <Gender nextStep={handleNext} subdomain={subdomain} />;
                }
            case 2:
                switch(caseValue) {
                    case "ActivityLevel":
                        return <ActivityLevel nextStep={handleNext} subdomain={subdomain} />;
                    case "Busy":
                        return <Busy nextStep={handleNext} subdomain={subdomain} />;
                    case "Children":
                        return <Children nextStep={handleNext} subdomain={subdomain} />;
                    case "Diet":
                        return <Diet nextStep={handleNext} subdomain={subdomain} />;
                    case "LifestyleResult":
                        return <LifestyleResult nextStep={handleNext} subdomain={subdomain} />;
                    default:
                        return <ActivityLevel nextStep={handleNext} subdomain={subdomain} />;
                }
            case 3:
                switch(caseValue) {
                    case "DanceExperience":
                        return <DanceExperience nextStep={handleNext} subdomain={subdomain} />;
                    case "DanceStyles":
                        return <DanceStyles nextStep={handleNext} subdomain={subdomain} />;
                    case "Times":
                        return <Times nextStep={handleNext} subdomain={subdomain} />;
                    case "Session":
                        return <Session nextStep={handleNext} subdomain={subdomain} />;
                    case "YogaOrFitness":
                        return <YogaOrFitness nextStep={handleNext} subdomain={subdomain} />;
                    case "DanceVsExercises":
                        return <DanceVsExercises nextStep={handleNext} subdomain={subdomain} />;
                    case "FirstQuestion":
                        return <FirstQuestion nextStep={handleNext} subdomain={subdomain} />
                    case "SecondQuestion":
                        return <SecondQuestion nextStep={handleNext} subdomain={subdomain} />
                    case "ThirdQuestion":
                        return <ThirdQuestion nextStep={handleNext} subdomain={subdomain} />
                    case "PlanResult":
                        return <PlanResult nextStep={handleNext} subdomain={subdomain} />
                    default:
                        return <DanceExperience nextStep={handleNext} subdomain={subdomain} />;
                }
            default:
                return 0;
        }
    }

    useEffect(() => {
        setStepComponent(findStep(progress))
    }, [progress, activeStep])

    const progressInPercent = Math.floor(100 * progress / activeStepsCount)
    const stepCase = stepsOrder[stepSections[activeStep]][progress];
    const stepsWithoutHeader = ["GoalBanner", "FirstQuestion", "SecondQuestion", "ThirdQuestion"]
    const isWithoutHeader = stepsWithoutHeader.indexOf(stepCase) > -1

    return (
        <div className={`${isWithoutHeader ? styles.disablePaddings : ''} container`}>
            {
                isWithoutHeader ? (
                    <>
                        {stepComponent}
                    </>
                ) : (
                    <div className={`flex-column wrapper`}>
                        <div className={`${styles.titleContainer} ${ progress === activeStepsCount ? styles.disablePaddings : ''} flex-row`}>
                            {
                                !(activeStep === 0 && progress === 0) && (
                                    <img className={styles.backButton} src="/images/icons/back-button.svg" alt="" onClick={handlePrev}/>
                                )
                            }
                            <h3 className={`${styles.title}`}>{title}</h3>
                        </div>
                        {
                            progress !== activeStepsCount && stepCase !== "DanceVsExercises" && (
                                <CustomStepper steps={steps} current={activeStep} progress={progressInPercent} />
                            )
                        }
                        {stepComponent}
                    </div>
                )
            }
        </div>
    );
}

export default StepsComponent;