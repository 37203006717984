import { useEffect } from "react";
import amplitude from 'amplitude-js';

import data from "../../../core/static/goal/mainGoal.json";
import useJsonParse from "../../../hooks/useJsonParse";
import SquareCard from "../../UI/squareCard/SquareCard";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./MainGoal.module.scss";

const MainGoal = props => {
    const { nextStep, subdomain } = props;
    const variantB = subdomain === 'b' || subdomain === 'h';
    const parsedData = useJsonParse(data[subdomain || 'a']);

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_main_goal_appeared');
    }, [])

    const selectCard = card => {
        if (card.id === 2 && !variantB) {
            localStorage.setItem('goal', 'Feel amazing');
        } else {
            localStorage.setItem('goal', card.title);
        }
        amplitude.getInstance().logEvent('button_onboarding_main_goal_tapped', {answer: card.title});
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>What is your main goal?</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>Our plan adapts to all types of goals.</span>
            { variantB ? (
                <div className={`${styles.cardsContainer} flex-row`}>
                    { parsedData?.map(card => (
                        <div key={card.id} className={styles.cardContainer}>
                            <SquareCard card={card} selectCard={selectCard}/>
                        </div>
                    )) }
                </div>
            ) : (
                <>
                    { parsedData?.map(card => (
                        <div key={card.id} className={styles.cardContainer}>
                            <TextCard card={card} selectCard={selectCard}/>
                        </div>
                    )) }
                </>
            )}
        </div>
    )
}   

export default MainGoal;