
import styles from "./TextCard.module.scss";

const TextCard = props => {
    const { card, cardHeight, selectCard, activeCard } = props;

    return (
        <>
            <div className={`${styles.cardContainer} ${activeCard?.id === card.id  ? styles.activeCard : ''} flex-row cardContainer`} style={{height: cardHeight ? `${cardHeight}px` : ''}} onClick={() => selectCard(card)}>
                <img className={styles.cardIcon} src={card.icon} alt="" />
                {
                    card.subtitle ? (
                        <div className={styles.contentContainer}>
                            <p className={`${styles.cardTitle} cardTitle`} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{card.title}</p>
                            <span className={styles.cardSubtitle} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{card.subtitle}</span>
                        </div>
                    ) : (
                        <p className={`${styles.cardTitle} cardTitle`} style={{paddingRight: card.titlePadding ? `${card.titlePadding}px` : ''}}>{card.title}</p>
                    )
                }
            </div>
            {
                card.message && activeCard?.id === card.id ? (
                    <p className={styles.cardMessage}>{card.message}</p>
                ) : null
            }
        </>
    )
}   

export default TextCard;