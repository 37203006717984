import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/plan/danceExperience.json";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./DanceExperience.module.scss";

const DanceExperience = props => {
    const { nextStep } = props;
    const [cards, setCards] = useState(data);
    const [activeCard, setActiveCard] = useState();

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_dance_experience_appeared');
    }, [])

    const selectCard = (card) => {
        setActiveCard(card);
        amplitude.getInstance().logEvent('button_onboarding_dance_experience_tapped', {answer: card.title});
        nextStep()
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>What would best describe your dance experience?</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>This will help us to improve your user experience in Everdance.</span>
            { cards?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard card={card} cardHeight={75} selectCard={selectCard} activeCard={activeCard}/>
                </div> )) 
            }
        </div>
    )
}   

export default DanceExperience;