import styles from "./RefundBox.module.scss";

const RefundBox = () => {
    return (
        <>
            <div className={styles.refundBox}>
                <div className={styles.refundBoxContainer}>
                    <div className={styles.refundBoxContent}>
                        <div className={styles.cupImage}>
                            <img src="/images/paywall/icons/goal-result.svg" alt="" />
                        </div>
                        <div className={styles.refundBoxTitle}>
                            <p>
                                Our users value us:
                                <br />
                                <span className={styles.boldText}> refund rate less than 2%*</span>
                                <br />
                                *based on 2022 data
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p className={styles.refundBoxText}>30-day <span className={styles.redText}>money back guarantee</span></p>
        </>
      )
  }

export default RefundBox;