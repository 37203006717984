import styles from "./RateCard.module.scss";

import CustomCheckbox from "../checkbox/Checkbox";

const RateCard = props => {
    const { card, setActiveCard, activeCard } = props;
    const selectCard = card => {
        setActiveCard(card)
    }
    let isActive = activeCard?.id === card?.id;

    return (
        <div className={`${styles.cardContainer} ${isActive ? styles.activeCard : ''} ${card?.id === 1 ? styles.popular : ''} flex-column`} onClick={() => setActiveCard({...card, checked: !card.checked})}>
            {
                card.id === 1 ? (
                    <div className={`${styles.header} flex-row`}>
                        <img src="/images/icons/like.png" alt="" />
                        <p>Most popular</p>
                    </div>
                ) : null
            }
            <div className={`${styles.wrapper} flex-between`}>
                <div className={`flex-row`}>
                    <CustomCheckbox data={card} checkCard={selectCard}/>
                    <div className={`${styles.priceContainer} flex-column`}>
                        <h3 className={styles.title}>{card.title}</h3>
                        <div className={`flex-row`}>
                            <p className={styles.oldPrice}>${card.oldTotal} <span></span></p>
                            <p className={styles.newPrice}>${card.total}</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.rightContent} flex-row`}>
                    <p className={`${styles.oldPricePerDay} ${styles.oldPrice}`}>${card.oldPerDay.toFixed(2)} <span></span></p>
                    <div className={`${styles.perDayContainer} flex-row`}>
                        <p className={styles.dollar}>$</p>
                        <h1 className={styles.beforeDot}>{Math.trunc(card.newPerDay)}</h1>
                        <div className={`${styles.subtitleContainer} flex-column`}>
                            <span className={styles.afterDot}>{Math.floor ((card.newPerDay - Math.floor (card.newPerDay)) * 100)}</span>
                            <p className={styles.subtitle}>per day</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default RateCard;