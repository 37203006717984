import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import useJsonParse from "../../../hooks/useJsonParse";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import SwiperComponent from "../../UI/swiperComponent/SwiperComponent";

import data from "../../../core/static/goal/body.json";

import styles from "./TargetBody.module.scss";

const TargetBody = props => {
    const { nextStep, subdomain } = props;
    const variantB = subdomain === 'b' || subdomain === 'h';
    const bodyImages = useJsonParse(data[subdomain || 'a']);
    const defaultSlide = variantB ? 1 : 5;

    const [currentSlide, setCurrentSlide] = useState(defaultSlide);

    const changeSlide = value => {
        setCurrentSlide(value)
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_target_body_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_target_body_tapped', {answer: currentSlide + 1});
        nextStep();
    }

    return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.cardTitle}>Choose your <span>desired</span> body shape</h1>
                <SwiperComponent data={bodyImages} defaultSlide={defaultSlide} currentSlide={currentSlide}  sliderTitle={'Target'} changeSlide={changeSlide}/>
                <div className={`${styles.sliderLabels} flex-between`}>
                    <span>{ variantB ? 'Medium' : 'Plump' }</span>
                    <span>{ variantB ? 'Curvy' : 'Skinny' }</span>
                </div>
                <ContinueButton nextStep={clickContinue}/>
            </div>
        </>
    )
}   

export default TargetBody;