import { useEffect } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/lifestyle/busy.json";
import useJsonParse from "../../../hooks/useJsonParse";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./Busy.module.scss";

const Busy = props => {
    const { nextStep } = props
    const parsedData = useJsonParse(data);

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_busy_appeared');
    }, [])

    const selectCard = card => {
        amplitude.getInstance().logEvent('button_onboarding_busy_tapped', {answer: card.title});
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>How busy are you on an average day?</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>This will help us find the best program for you.</span>
            { parsedData?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard card={card} selectCard={selectCard} cardHeight={65} nextStep={nextStep}/>
                </div> )) 
            }
        </div>
    )
}   

export default Busy;