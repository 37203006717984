import styles from "./MealPlanBonus.module.scss";
import { useRef } from "react";

import GetButton from "../getProgramButton/GetButton";
import CustomCheckbox from "../checkbox/Checkbox";

const MealPlanBonus = () => {
    const ref = useRef(null);
    const scrollTo = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const checkboxData = {
        "checked": true,
        "disabled": true
    }

    return (
        <div className={styles.mealPlanBonus}>
            <div className={styles.mealPlanBonusAdvantages}>
                <div className={styles.mealPlanBonusAdvantagesGroup}>
                    <div className={styles.mealPlanBonusAdvantage}>
                        <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                        <div className={styles.advantagePoint}>
                            Full access to <br/> 350+ dance <br/> classes
                        </div>
                    </div>
                    <div className={styles.mealPlanBonusAdvantage}>
                        <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                        <div className={styles.advantagePoint}>
                            Feedback from <br/> dance instructors
                        </div>
                    </div>
                </div>
                <div className={styles.mealPlanBonusAdvantagesGroup}>
                    <div className={styles.mealPlanBonusAdvantage}>
                        <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                        <div className={styles.advantagePoint}>
                            Just 15 minutes per <br/> day to see <br/> noticeable results
                        </div>
                    </div>
                    <div className={styles.mealPlanBonusAdvantage}>
                        <CustomCheckbox data={checkboxData} checkCard={()=>{}}/>
                        <div className={styles.advantagePoint}>
                            Personal <br/> dance plan
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.mealPlanBonusBox}>
                <div className={styles.mealPlanBonusContainer}>
                    <div className={styles.mealPlanBonusContent}>
                        <div className={styles.giftImage}>
                            <img src="/images/paywall/icons/surprise-icon.png" alt="" />
                        </div>
                        <div className={styles.mealPlanBonusTitle}>
                            <h2>
                                <span className={styles.redText}>+ FREE BONUS:</span>
                                <br/>
                                Meal Plan &
                                <br/>
                                HIIT Workouts Program
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <GetButton text={'Continue'} nextPage={ scrollTo } />
            <div ref={ref} className={styles.mealPlanBonusRef} />
        </div>
      )
  }

export default MealPlanBonus;
