import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/lifestyle/children.json";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import TextCard from "../../UI/textCard/TextCard";

import styles from "./Children.module.scss";

const Children = props => {
    const { nextStep } = props;
    const [cards, setCards] = useState(data);
    const [activeCard, setActiveCard] = useState();
    const [disableButton, setDisableButton] = useState(true);

    const selectCard = (card) => {
        setActiveCard(card);
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_children_appeared');
    }, [])

    useEffect(() => {
        if(activeCard) {
            setDisableButton(false);
        }
        return () => setDisableButton(true);
    }, [activeCard])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_children_tapped', {answer: activeCard.title});
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>Do you have children?</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>This will help us find the best program for you.</span>
            { cards?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard card={card} selectCard={selectCard} activeCard={activeCard}/>
                </div> )) 
            }
            <div className={styles.buttonContainer}>
                <ContinueButton nextStep={clickContinue} disableButton={disableButton}/>
            </div>
        </div>
    )
}   

export default Children;