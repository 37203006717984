import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from "react-router";
import { Pagination, Navigation } from "swiper";
import { useDispatch } from "react-redux";
import { useFeatureValue } from "@growthbook/growthbook-react";
import amplitude from 'amplitude-js';

import GetButton from "../../components/UI/getProgramButton/GetButton";
import ResultsCard from "../../components/UI/paywallResultsCard/ResultsCard";
import StartTrial from "../../components/UI/startTrial/StartTrial";
import MealPlanBonus from "../../components/UI/mealPlanBonus/MealPlanBonus";

import paywallData from "../../core/static/paywall.json";
import paywallTestData from "../../core/static/paywall_test.json";
import styles from "./Paywall.module.scss";
import "./Paywall.scss";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import PaymentRateCards from "../../components/UI/paymentRateCards/PaymentRateCards";

const Paywall = () => {
    const liveMode = localStorage.getItem('liveMode') === "true"
    const data = liveMode ? paywallData : paywallTestData;
    const goal = localStorage.getItem('goal') || "Stay fit";
    const discountStatus = localStorage.getItem('discountStatus');
    const targetWeight = localStorage.getItem('targetWeight');
    const location = localStorage.getItem('location');
    const subdomain = liveMode ? localStorage.getItem('subdomain') : 'a';
    const [test, setTest] = useState('b');
    const [content, setContent] = useState(data);
    const [textButton, setTextButton] = useState('Continue');
    const [rateCards, setRateCards] = useState([]);
    const [activeRate, setActiveRate] = useState(content.rate[location][1]);
    const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(0);
    const [miliseconds, setMiliseconds] = useState(0);
    const [stopTimer, setStopTimer] = useState(false);
    const [[m, s, ms], setTime] = useState([minutes, seconds, miliseconds]);
    const value = useFeatureValue("paywall_testing", "fallback");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAEuCase = subdomain === 'a' && location === 'eu'
    const isBCase = subdomain === 'b' || subdomain === 'h'
    const feedbacks = content.results[subdomain || 'a'][location]

    useEffect(() => {
        if(value === 'a' || value === 'b' || value === 'c') {
            setRateCards(data.rate[location][value])
            localStorage.setItem('period', JSON.stringify(data.rate[location][value][1]))
            setActiveRate(data.rate[location][value][1])
            setTest(value)
            amplitude.getInstance().logEvent('screen_onboarding_paywall_appeared', { paywall: `${value}_${location}`});
        }
        if(discountStatus && discountStatus === 'trial') {
            setTextButton('Start my 7 day trial')
            setRateCards(data.rate[location]['trial'])
            localStorage.setItem('period', JSON.stringify(data.rate[location]['trial'][1]))
            setActiveRate(data.rate[location]['trial'][1])
            setTest('trial')
            amplitude.getInstance().logEvent('screen_onboarding_paywall_appeared', {paywall: `trial_${location}`});
        }
    }, [value, discountStatus])

    const setActiveRateCard = (card) => {
        if(card.checked) {
            setRateCards(rateCards.map(item => {
                if(card.id === item.id) {
                    return {...item, checked: card.checked}
                } else {
                    return {...item, checked: false}
                }
            }))
            setActiveRate(card)
            dispatch({type: 'SELECT_PERIOD', payload: card})
        }
    }

    useEffect(() => {
        localStorage.setItem('period', JSON.stringify(activeRate))
    }, [activeRate])

    const nextPage = () => {
        amplitude.getInstance().logEvent('button_onboarding_paywall_subscribe_tapped', { paywall: value, product: `$${activeRate.total}_${activeRate.title}`});
        localStorage.setItem('timer', JSON.stringify({ minutes: m, seconds: s, miliseconds: ms}));
        setStopTimer(true);
        navigate('/payment')
    }

    const tick = () => {
        if(stopTimer) return;
        if (m === 0 && s === 0 && ms === 0) {
            setStopTimer(true);
        } else if (s === 0 && ms === 0) {
            setTime([m - 1, 59, 59]);
        } else if (ms === 0) {
            setTime([m, s - 1, 59]);
        } else {
            setTime([m, s, ms - 1]);
        }
    }

    useEffect(()=>{
        if(localStorage.getItem('timer')) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            setTime([timer.minutes, timer.seconds, timer.miliseconds]);
            localStorage.removeItem('timer');
        }
        const timerID = setInterval(() => tick(), 17);
        dispatch({type: 'SELECT_PERIOD', payload: activeRate})
        return () => clearInterval(timerID);
    })

    return (
        <div className={`${styles.mainContainer}`}>
            <div className={`${styles.logoContainer} flex-between`}>
                <img src="/images/Logo.svg" alt="" />
                {
                    !stopTimer ? (
                        <div className={`${styles.timerContainer} flex-column`}>
                            <p>Reserved price for:</p>
                            <span>{`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}:${ms.toString().padStart(2, '0')}`}</span>
                        </div>
                    ) : (
                        <GetButton small={true} text={textButton} nextPage={nextPage}/>
                    )
                }
            </div>
            { discountStatus ? (<StartTrial />) : null }
            <img className={styles.galleryImage} src="/images/paywall/a/paywall-a.gif" alt="" />
            <h1 className={styles.title1}>Your <span>personalized</span> 30-day program is ready</h1>
            <div className={`${styles.selectedDataContainer} flex-between`}>
                <div className={`${styles.selectedItem} flex-row`}>
                    <div className={styles.circleContainer}>
                        <img src="/images/result/goal-result.jpg" alt="" />
                    </div>
                    <div className={`${styles.itemText} flex-column`}>
                        <p className={styles.label}>Goal</p>
                        <p className={styles.content}>{goal}</p>
                    </div>
                </div>
                <div className={styles.verticalLine}></div>
                <div className={`${styles.selectedItem} flex-row`}>
                    <div className={styles.circleContainer}>
                        <img src="/images/result/scales.jpg" alt="" />
                    </div>
                    <div className={`${styles.itemText} flex-column`}>
                        <p className={styles.label}>Target weight</p>
                        <p className={styles.content}>{targetWeight}</p>
                    </div>
                </div>
            </div>
            { isAEuCase || isBCase ? (<MealPlanBonus />) : null }
            <h1 className={styles.title1}>Choose your plan</h1>
            <PaymentRateCards textButton={textButton} nextPage={nextPage} rateCards={rateCards}
                              activeRate={activeRate} setActiveRateCard={setActiveRateCard} isAEuCase={isAEuCase || isBCase} />
            <p className={styles.rateNote}>*You can cancel anytime. All prices are in USD.</p>
            {
                !(isAEuCase || isBCase) ? (
                    <>
                        <h2 className={styles.titleProgramDescription}>What you get</h2>
                        <div className={styles.descriptionContainer}>
                            { content.programDescription.map(item => (
                                <div key={item.id} className={`${styles.itemContainer} flex-row`}>
                                    <img src={item.icon} alt="" />
                                    <p>{item.text}</p>
                                </div>
                            ))}
                        </div>
                        {/*<GetButton text={textButton} nextPage={nextPage}/>*/}
                    </>
                ) : null
            }
            <h2 className={`${styles.titleProgramDescription} ${styles.marginTitle}`}>As featured in:</h2>
            <img className={styles.featuresImage} src="/images/paywall/features-image.png" alt="" />
            <div className={`${styles.resultsContainer} resultsContainer flex-column`}>
                <h2>People just like you achieved great results using our <span>Dancing Weight Loss Plan</span></h2>
                <Swiper
                    className="mySwiper"
                    spaceBetween={30}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    pagination={{
                    clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                >
                    {
                        feedbacks.map(result => (
                            <SwiperSlide key={result.id}>
                                <ResultsCard card={result}/>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
            <p className={styles.disclaimer}><b>Disclaimer:</b> Following Exercise and a meal plan is the key in your fitness journey and greatly the results. It’s unusual to lose more than 4 kg per month. Consult a physician first.</p>
            <PaymentRateCards textButton={textButton} nextPage={nextPage} rateCards={rateCards}
                              activeRate={activeRate} setActiveRateCard={setActiveRateCard} isAEuCase={isAEuCase || isBCase} />
            <p className={styles.rateNote}>*You can cancel anytime. All prices are in USD.</p>
            <h2 className={styles.questionsTitle}>People Often Ask</h2>
            {
                content.questions.map(question => (
                    <div className={styles.questionContainer} key={question.id}>
                        <div className={`${styles.titleContainer} flex-row`}>
                            <img src="/images/paywall/question-icon.png" alt="" />
                            <p>{question.title}</p>
                        </div>
                        <p className={styles.subtitle}>{question.subtitle}</p>
                    </div>
                ))
            }
            <h2 className={`${styles.questionsTitle} ${styles.marginFeedbackTitle}`}>Users love our plans</h2>
            {
                content.comments.map(comment => (
                    <div key={comment.id} className={styles.commentContainer}>
                        <div className={styles.titleContainer}>
                            <h2>{comment.title}</h2>
                            <img src="/images/paywall/stars-image.jpg" alt="" />
                        </div>
                        <p>{comment.subtitle}</p>
                        <div className={`${styles.authorContainer} flex-between`}>
                            <span>{comment.author}</span>
                            <span>{comment.date}</span>
                        </div>
                    </div>
                ))
            }
            <div className={styles.guaranteeContainer}>
                <div className={styles.wrapper}>
                    <h2>30-Day Money Back Guarantee</h2>
                    <p>We belive that our plan may work for you and you’ll get visible results in 4 weeks!<br/><br/>We are even ready to return your money back if you don’t see visible results and can demonstrate that you followed our plan.</p>
                    <img src="/images/paywall/guarantee-icon.png" alt="" />
                </div>
            </div>
            <p className={styles.guaranteeNote}><b>Please note:</b> Subscriptions renew automatically at the end of each period unless you cancel it. If you want to cancel a subscription, write to our Support. Prepayment of total plan cost required. You will need an Android or iOS mobile phone to access the full version of the product. You may want to take a screenshot of this information and save it.</p>
            <GetButton text={textButton} nextPage={nextPage}/>
        </div>
    )
}   

export default Paywall;