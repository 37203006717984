import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import useJsonParse from "../../../hooks/useJsonParse";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import SwiperComponent from "../../UI/swiperComponent/SwiperComponent";

import data from "../../../core/static/goal/body.json";

import styles from "./CurrentBody.module.scss";

const CurrentBody = props => {
    const { nextStep, subdomain } = props;
    const bodyImages = useJsonParse(data['a']);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_current_body_appeared');
    }, []);

    const changeSlide = value => {
        setCurrentSlide(value)
    }

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_current_body_tapped', {answer: currentSlide + 1});
        nextStep();
    }

    return (
        <>
            <div className={`${styles.mainContainer} flex-column`}>
                <h1 className={styles.cardTitle}>Choose your body shape</h1>
                <SwiperComponent data={bodyImages} defaultSlide={0} currentSlide={currentSlide} sliderTitle={'Current'} changeSlide={changeSlide}/>
                <div className={`${styles.sliderLabels} flex-between`}>
                    <span>Plump</span>
                    <span>Skinny</span>
                </div>
                <ContinueButton nextStep={clickContinue}/>
            </div>
        </>
    )
}   

export default CurrentBody;