import { useEffect } from 'react';
import amplitude from 'amplitude-js';

import styles from "./ThirdQuestion.module.scss";

const ThirdQuestion = props => {
    const { nextStep } = props;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_question3_appeared');
    }, [])

    const clickButton = value => {
        amplitude.getInstance().logEvent('button_onboarding_question3_tapped', {answer: value});
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <div className={`${styles.contentContainer} questionContainer`}>
                <div className={`buttonsContainer flex-between`}>
                <h1 className="questionTitle">Do you wanna <br/> farewell to <br/> chronic <br/> diseases?</h1>
                    <div className={`${styles.buttonsContainer} flex-between`}>
                        <button className={`button firstButton`} onClick={() => clickButton('no')}>No</button>
                        <button className={`button secondButton`} onClick={() => clickButton('yes')}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default ThirdQuestion;