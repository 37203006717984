import { useState, useMemo, useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import Picker from "../../UI/picker/Picker";
import SwitcherComponent from "../../UI/switcher/Switcher";
import styles from "./Height.module.scss";
import AboutInput from "../../UI/aboutInput/AboutInput";

const ftHeight = [3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 4.0, 4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5.0, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6.0, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7.0, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9];
const cmHeight =  Array.from(Array(241).keys()).slice(90);

const Height = props => {
    const { nextStep, subdomain } = props;
    const [height, setHeight] = useState(null);
    const [pickerHeight, setPickerHeight] = useState(null);
    const [pickerData, setPickerData] = useState(ftHeight);
    const [selected, setSelected] = useState(1);
    const [disableButton, setDisableButton] = useState(true);
    const [errorMessage, setErrorMessage] = useState("")
    const [buttonStyles, setButtonStyles] = useState({})
    const [inputFt, setInputFt] = useState('');
    const [inputIn, setInputIn] = useState('');
    const [inputSm, setInputSm] = useState('');
    const variantB = subdomain === 'b' || subdomain === 'h';

    useEffect(() => {
        setDisableButton(true);
        amplitude.getInstance().logEvent('screen_onboarding_height_appeared');
        return () => {
            setDisableButton(true);
        }
    }, [])

    const options = useMemo(() => [
        { value: 1, text: 'FT' },
        { value: 2, text: 'CM' }
    ], []);

    const collectHeight = (data) =>{
        let ftV = inputFt;
        let inV = inputIn;
        if(data.inputType === 'FT') {
            setInputFt(data.value)
            ftV = data.value
        }
        if(data.inputType === 'IN') {
            setInputIn(data.value)
            inV = data.value
        }
        if(ftV && inV) {
            let newHeight = (ftV * 1) + (inV / 10)
            if(newHeight < 4.3) {
                setErrorMessage("Value must be greater than or equal to 4’ 3’’");
                setDisableButton(true)
                return
            }
            if(newHeight > 8.0){
                setErrorMessage("Value must be less than or equal to 8’ 0’’");
                setDisableButton(true)
                return
            }
            setHeight((newHeight * 30.48).toFixed())
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    const changeInputHeight = data => {
        setErrorMessage(data.errorMessage);
        if(data.inputType === 'CM') {
            setDisableButton(data.disableButton);
            if (!data.disableButton)
                setInputSm(data.value)
                setHeight(data.value);
        } else {
            collectHeight(data)
        }
    }

    const changeSwipedHeight = data => {
        if(selected === 1) {
            setHeight(pickerData.find((el, index) => index === data))
        } else {
            setHeight(data + 90)
        }
        setDisableButton(false);
    }

    const inputOnFocus = () => {
        setTimeout(() => {
            setButtonStyles({ position: 'relative', marginTop: '45px', left: 'unset' })
        }, 200);
    };

    const inputOnBlur = () => {
        setTimeout(() => {
            setButtonStyles({})
        }, 200)
    };

    useEffect(() => {
        if(selected === 1) {
            if(!variantB){
                let calculatedValue = height ? (height / 30.48).toFixed(1) : 5.5
                setPickerData(ftHeight);
                setHeight(calculatedValue);
            } else {
                let [whole, dec] = height ? (height / 30.48).toFixed(1).toString().split('.') : ['', '']
                setInputIn(dec)
                setInputFt(whole)
            }

        } else {
            let calculatedValue = (height * 30.48).toFixed()
            if(!variantB){
                setPickerData(cmHeight)
                setHeight(calculatedValue);
            } else {
                setInputSm(height)
            }
        }
    }, [selected])

    useEffect(() => {
        if(variantB) return;

        if(selected === 1) {
            setPickerHeight(pickerData.indexOf(+height))
        } else if(selected === 2) {
            setPickerHeight(pickerData.indexOf(Math.trunc(height)))
        }
    }, [height]);

    const clickContinue = () => {
        let value = height
        if(selected === 1 && !variantB)
            value = height * 30.48
        amplitude.getInstance().logEvent('button_onboarding_height_tapped', {answer: value});
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>Your height</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>This will allow us define your body mass index and adapt your workout plan.</span>
            <SwitcherComponent options={options} value={selected} onChange={setSelected} />
            { variantB ? (
                <>
                    <div className={`${styles.inputArea} flex-row`}>
                        {selected === 1 ? (
                            <>
                                <AboutInput
                                    checkResult={changeInputHeight}
                                    onFocus={inputOnFocus}
                                    onBlur={inputOnBlur}
                                    currentValue={inputFt}
                                    inputType={'FT'}
                                    min={4}
                                    max={8}
                                />
                                <AboutInput
                                    checkResult={changeInputHeight}
                                    onFocus={inputOnFocus}
                                    onBlur={inputOnBlur}
                                    currentValue={inputIn}
                                    inputType={'IN'}
                                    min={0}
                                    max={9}
                                />
                            </>
                            ) : (
                                <AboutInput
                                    checkResult={changeInputHeight}
                                    onFocus={inputOnFocus}
                                    onBlur={inputOnBlur}
                                    currentValue={inputSm}
                                    inputType={'CM'}
                                    min={130}
                                />
                            )
                        }
                    </div>
                    { errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>) }
                    <ContinueButton customStyles={buttonStyles} nextStep={clickContinue} disableButton={disableButton}/>
                </>
                ) : (
                    <>
                        <h1 className={styles.heightTitle}>{selected === 1 ? height : Math.trunc(height)}</h1>
                        <span className={styles.heightSubtitle}>{options.find(el => el.value === selected).text}</span>
                        <div className={styles.pickerContainer}>
                            <Picker data={pickerData} currentValue={25} convertedValue={pickerHeight} swipeValue={changeSwipedHeight}/>
                            <ContinueButton nextStep={clickContinue} disableButton={disableButton}/>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default Height;