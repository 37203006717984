import React from "react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import styles from './RegistrationStepper.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    left: '-22px',
    right: '-43px',
    height: 4,
    width: '100%',
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      height: 4,
      zIndex: 1
    },
    [`&::after`]: {
        left: 0,
        position: 'absolute',
        top: '50%',
        height: '2px',
        backgroundColor: '#E8E8E8'
    }
  }));

function RegistrationStepper(props) {
  const { currentStep, steps } = props;
  
  function renderStep(step, key) {
    const { currentStep } = this;
    const done = key < currentStep;
    const current = key + 1 === currentStep;
    const stepIconClass = (current && currentStep === 1) ? styles.failedStep :
                           current ? styles.progressStep :
                           (done ? styles.completedStep : styles.inactiveStep)

    return (
      <li className={styles.registrationStepper__step} key={key}>
        <div className={styles.titleContainer}>
          <span className={`${styles.title} ${current && styles.activeTitle}`}>{step.title}</span>
        </div>
        <div className={styles.labelContainer}>
            <span className={`${styles.defaultStep} ${stepIconClass}`}></span>
            {!!key && <BorderLinearProgress variant="determinate" value={done ? 100 : 0} className={`linearProgress`} />}
        </div>
      </li>
    )
  }

  return (
    <ul className={styles.registrationStepper}>
      {steps.map(renderStep, { currentStep })}
    </ul>
  )
}

export default RegistrationStepper;