
import styles from "./ButtonCard.module.scss";

const ButtonCard = props => {
    const { card, selectCard, activeCard } = props;

    return (
        <div
            className={`${styles.cardContainer} ${activeCard?.id === card.id  ? styles.activeCard : ''} cardContainer`}
            onClick={() => selectCard(card)}
        >
            <p className={`${styles.cardTitle}`} >{card.title}</p>
        </div>
    )
}   

export default ButtonCard;