import styles from "./PaymentRateCards.module.scss";
import RateCard from "../rateCard/RateCard";
import GetButton from "../getProgramButton/GetButton";
import RefundBox from "../refundBox/RefundBox";

const PaymentRateCards = props => {
    const { textButton, nextPage, rateCards, activeRate, setActiveRateCard, isAEuCase } = props;
    return (
       <>
           <div className={`${styles.cardsContainer} flex-column`}>
               {
                   rateCards.map(card => <RateCard key={card.id} activeCard={activeRate} setActiveCard={setActiveRateCard} card={card} />)
               }
           </div>
           { isAEuCase ? (<RefundBox />) : null }
           <GetButton text={textButton} nextPage={nextPage}/>
       </>
      )
  }

export default PaymentRateCards;
