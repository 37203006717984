import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';

import data from "../../../core/static/plan/yoga.json";
import CustomCheckbox from "../../UI/checkbox/Checkbox";
import CheckboxImageCard from "../../UI/checkboxImageCard/CheckboxImageCard";
import ContinueButton from "../../UI/continueButton/ContinueButton";

import styles from "./YogaOrFitness.module.scss";

const YogaOrFitness = props => {
    const { nextStep } = props;
    const [activeCard, setActiveCard] = useState([]);
    const [noneCard, setNoneCard] = useState({ id: 2, title: 'none', checked: false });
    const [cards, setCards] = useState(data);
    const [disableButton, setDisableButton] = useState(true);

    const selectCard = card => {
        if(card.checked) {
            setActiveCard((prev => [...prev, card]))
        } else {
            const removeCardIndex = activeCard.findIndex(el => el.id === card.id);
            setActiveCard([...activeCard.slice(0, removeCardIndex), ...activeCard.slice(removeCardIndex + 1)])
        }
    };

    const checkNoneCard = card => {
        setNoneCard(card);
    };

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_yoga_fitness_appeared');
    }, [])

    useEffect(() => {
        if(activeCard.length !== 0 || noneCard.checked) {
            setDisableButton(false)
        }
        
        return () => setDisableButton(true)
    }, [activeCard, noneCard])

    useEffect(() => {
        if(noneCard.checked) {
            setActiveCard([])
            setCards(prev => prev.map(card => {
                return {...card, checked: false} 
            }))
        }
    }, [noneCard]);

    useEffect(() => {
        if(activeCard.length !== 0) {
            activeCard.forEach(active => {
                setCards(cards.map(card => {
                    if(card.id === active.id) {
                        return active
                    } else {
                        return card
                    }
                }))
            })
            setNoneCard(prev => ({...prev, checked: false}));
        }
    }, [activeCard])

    const clickContinue = () => {
        if(noneCard.checked) {
            amplitude.getInstance().logEvent('button_onboarding_yoga_fitness_tapped', { answer: noneCard.title});
        } else {
            amplitude.getInstance().logEvent('button_onboarding_yoga_fitness_tapped', {answer: activeCard.map(card => { return card.title})});
        }
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>Do you want to include yoga and fitness to your  plan?</h1>
            <div className={`${styles.imageContainer} flex-between`}>
                { cards.map(card => <CheckboxImageCard key={card.id} selectCard={selectCard} data={card}/>) }
            </div>
            <div className={`${styles.checkboxContainer} flex-row`}>
                <CustomCheckbox data={noneCard} checkCard={checkNoneCard}/>
                <span>None</span>
            </div>
            <ContinueButton nextStep={clickContinue} disableButton={disableButton}/>
        </div>
    )
}   

export default YogaOrFitness;