import { useEffect } from 'react';
import amplitude from 'amplitude-js';

import styles from "./SecondQuestion.module.scss";

const SecondQuestion = props => {
    const { nextStep } = props;

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_question2_appeared');
    }, [])

    const clickButton = value => {
        amplitude.getInstance().logEvent('button_onboarding_question2_tapped', {answer: value});
        nextStep();
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <div className={`${styles.contentContainer} questionContainer`}>
                <div className={`buttonsContainer flex-between`}>
                    <h1 className="questionTitle">Do you wanna <br/> get an <br/> attractive <br/> body?</h1>
                    <div className={`${styles.buttonsContainer} flex-between`}>
                        <button className={`button firstButton`} onClick={() => clickButton('no')}>No</button>
                        <button className={`button secondButton`} onClick={() => clickButton('yes')}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default SecondQuestion;