import styles from "./ResultsCard.module.scss";

const ResultsCard = props => {
    const { card } = props;

    return (
        <div className={`${styles.cardContainer}`}>
            <div className={`${styles.imagesContainer} flex-between`}>
                <div className={styles.imageContainer}>
                    <img src={card.before.image} alt="" />
                    <p>{card.before.date}</p>
                </div>
                <div className={styles.imageContainer}>
                    <img src={card.after.image} alt="" />
                    <p className={styles.afterText}>{card.after.date}</p>
                </div>
            </div>
            <div className={`${styles.dataContainer} flex-between`}>
                <p>{card.name}</p>
                <p className={styles.weightData}>{card.weight}</p>
            </div>
            <p className={styles.feedback}>{card.feedback}</p>
        </div>
    )
}   

export default ResultsCard;